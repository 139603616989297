<template>
  <div style="width:100vw;height:100vh;overflow:hidden" :headers="headers">
    <!-- <h1>正在进行跳转...</h1> -->
  </div>
</template>
<script>
import { Loading } from "element-ui";
import { getuserinfo } from "@/api/user";
import {
  setIdentifierToken,
  setWebNameToken,
  setWebAvaterToken,
  setRedirecttypeToken,
  setSignToken,
  setToken
} from "@/utils/auth";

export default {
  name: "noPasswordLogin",
  data() {
    return {
      // headers: {
      //   'redirecttype': '',
      //   'sign': '',
      // },
    };
  },
  computed: {
    token() {
      return this.$route.query.token
    },
    identifier() {
      return this.$route.query.identifier
    },
    schoolid() {
      return this.$route.query.schoolid
    },
    redirecttype() {
      return this.$route.query.redirecttype
    },
    sign() {
      return this.$route.query.sign
    },
  },
  created() {
    this.token = this.$route.query.token;
    this.identifier = this.$route.query.identifier;
    this.schoolid = this.$route.query.schoolid;
    this.redirecttype = this.$route.query.redirecttype;
    this.sign = this.$route.query.sign;

    console.log('sign_on正在进行跳转----------', this.token, '--/--', this.identifier, '--/--', this.schoolid);
    let loadingInstance = Loading.service({
      text: "正在进行跳转...",
      background: "rgba(0, 0, 0, 0.7)",
      lock: true,
      spinner: "el-icon-loading",
      fullscreen: true
    });
    if (this.token && this.identifier && this.schoolid) {
      this.$store.commit("SET_MENU_ID", 0);
      console.log('state.menuactiveid', this.$store.state.menuactiveid);
      window.localStorage.setItem("menuId", 0);
      console.log('menuId----------', window.localStorage.getItem("menuId"));
      setToken(this.token); // 登录成功保存token到cookie 字段为Admin-Token
      this.$store.commit("SET_TOKEN", this.token); // 登录成功保存token到vuex全局state
      console.log('state.token', this.$store.state.token);
      // console.log("this.identifier",this.identifier);
      setIdentifierToken(this.identifier);
      setRedirecttypeToken(this.redirecttype)
      setSignToken(this.sign)
      // this.headers['redirecttype']= this.redirecttype;
      // this.headers['sign'] = this.sign;
      this.$store.commit("SET_Identifier", this.identifier); //获取用户身份信息
      console.log('state.identifier----------', this.$store.state.identifier);
      this.$nextTick(() => {
        loadingInstance.close()
        this.getuserinfo()
      })
    }
  },
  methods: {
    getuserinfo() {
      getuserinfo()
        .then((response) => {
          if (response.code == 0) {
            console.log("response", response);
            this.$store.commit("SET_Username", response.data.name);
            setWebNameToken(response.data.name);
            this.$store.commit("SET_Useravater", response.data.avatar);
            setWebAvaterToken(response.data.avatar);
            this.$router.push({ path: "/home" });
          }
        })
        .catch((error) => { });
    },
  }
};
</script>
<style scoped>
h1 {
  font-size: 30px;
  color: darkcyan;
}
</style>

